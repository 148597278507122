.EditPost {
  position: relative;
}

.EditPost-inputs-container {
  margin: 130px 40px 100px;
}

.EditPost-btn,
.EditPost-save-btn {
  padding: 15px 18px;
  color: #fff;
  font-weight: 900;
  border: none;
  border-radius: 4px;
  background: rgba(113, 113, 113, 0.7);
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  margin: 5px;
}

.EditPost-btn {
  display: block;
  margin-bottom: 30px
}

.EditPost-btn:hover,
.EditPost-save-btn:hover {
  background: rgba(113, 113, 113, 0.8);
}

.Images-visualization-wrapper {
  margin-top: 50px;
  padding: 20px 0
}

.Images-visualization-title {
  font-size: 18px;
  font-weight: 800;
}

.Images-visualization-list-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  overflow: hidden;
  background: #ccc;
  margin-top: 30px;
  padding: 30px 0
}

@media(max-width: 670px) {
  .Images-visualization-wrapper {
    margin: 0 20px;
  }
}

.Images-visualization-image {
  height: 480px; 
  overflow: hidden;
}

@media(max-width: 870px) {
  .Images-visualization-image {
    width: 100%;
    padding: 0;
    height: auto;
  }
}

.Images-visualization-image img {
  width: 100%;
  height: 100%;
}

.EditPost-button-wapper {
  margin-top: 50px;
  padding: 10px 0;
  position: sticky;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
}

.EditPost-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0, .3);
  z-index: 2;
}

.EditPost-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 400px;
  width: 500px;
  background: #fff;
}

.EditPost-modal-selector-wrapper {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.EditPost-modal-selector {
  display: block;
  width: 80px;
  margin: 20px 0 20px;
}


.EditPost-Bin {
  width: 30px;
}

.EditPost-Bin:hover {
  opacity: .6;
}