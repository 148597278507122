.PopUp {
  width: 200px;
  position: absolute;
  bottom: 72px;
  right: 25px;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(49, 221, 132);
  transition: all 1s;
}
.PopUp-msg {
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  padding: 0 10px;
  margin-top: 20px;
  opacity: 1;
  transition: opacity 0.5s;
}
.PopUp--opened {
  height: 70px;
}
.PopUp--closed {
  height: 0;
}
.PopUp--closed .PopUp-msg {
  opacity: 0;
}
