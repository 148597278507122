.PostList {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  margin: 30px 0;
  background: rgba(165, 174, 169, 0.2);
}

.PostListTitle {
  font-size: 22px;
  font-weight: 600;
}

.PostListButtonWrapper {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, .2)
}

.PostListButton {
  padding: 10px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, .2);
  border-bottom: none;
}

.PostListButton.active {
  background-color: rgba(0, 0, 0, .2);
  color: #fff
}

.PostListButton:hover {
  opacity: .8;
}
