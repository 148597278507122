.Card {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 360px;
}

.PostCard-cardMedia {
  height: 150px;
  margin-bottom: 25px;
}
.PostCard-cardContent {
  height: 130px;
  max-height: 150px;
  margin: 0 10px 10px
}

.PostCard-CardActions {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.PostCard-title {
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.4px;
  text-align: left
}
.PostCard-excerpt {
  color: #333;
  letter-spacing: 0.1px;
  line-height: 17px;
  text-align: justify
}
.PostCard-date {
  position: absolute;
  bottom: 0;
  right: 10px;
  color: grey;
  letter-spacing: 0.1px;
  line-height: 17px;
  font-size: 12px;
}

.PostCard-link {
  text-decoration: none;
}

.PostCard-bin{
  width: 16px;
  top: 5px;
  cursor: pointer;
  right: 10px;
  position: absolute;
}

.PostCard-bin:hover{
  opacity: .7
}